function SearchFilter(values, currentFilterCriteria, filterCriterias) {

    const formFilter = () => {
        let criteria = [];
        let filterCriteria = currentFilterCriteria ? currentFilterCriteria : filterCriterias;
        filterCriteria.criteria = [];

        criteria.push({ key: "propertyStatus", value: ["New"], type: "in" });
        if (values.propertyFor) {
            criteria.push({ key: 'propertyFor', value: values.propertyFor, type: 'eq' })
        }
        if (values.propertyType) {
            criteria.push({ key: 'propertyType', value: values.propertyType, type: 'eq' })
        }
        if (values.locality) {
            criteria.push({ "key": "$or", "value": ["city", "locality", values.locality], "type": "value" })
        }
        if (values.totalPriceMin || values.totalPriceMax) {
            const range = {};
            if (values.totalPriceMin) range["$gte"] = values.totalPriceMin;
            if (values.totalPriceMax) range["$lte"] = values.totalPriceMax;

            criteria.push({
                key: '$or',
                value: ["price", "minPrice", "maxPrice", range],
                type: 'value1'
            });
        }
        if (values.builtUpAreaMin || values.builtUpAreaMax) {
            const range = {};
            if (values.builtUpAreaMin) range["$gte"] = values.builtUpAreaMin;
            if (values.builtUpAreaMax) range["$lte"] = values.builtUpAreaMax;

            criteria.push({
                key: '$or',
                value: ["builtUpArea", "minArea", "maxArea", range],
                type: 'value1'
            });
        }
        if (values.facing?.length > 0) {
            criteria.push({ key: 'facing', value: values.facing, type: 'in' })
        }
        if (values.bedRooms?.length > 0) {
            criteria.push({ key: 'bedRooms', value: values.bedRooms, type: 'in' })
        }
        if (values.availability?.length > 0) {
            criteria.push({ key: 'availability', value: values.availability, type: 'in' })
        }
        if (values.furnishingType?.length > 0) {
            criteria.push({ key: 'furnishingType', value: values.furnishingType, type: 'in' })
        }
        if (values.amenties?.length > 0) {
            criteria.push({ key: 'amenties', value: values.amenties, type: 'in' })
        }

        if (criteria && criteria.length > 0) {
            filterCriteria.criteria = criteria;
        } else {
            filterCriteria.criteria = [];
        }
        if (values && values.facing === null) {
            delete values['facing'];
        }
        
        return filterCriteria;
    };

    return formFilter();
}
export default SearchFilter;
