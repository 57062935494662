import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import getClientFormFields, { SoldPriceOptions, getAmenities } from "../../../../config/budgetOptions";
import ClientSelectModal from "../../CommonModals/ClientSelectModal";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// config file

const Searches = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [isOpenClientSelectModel, setIsOpenClientSelectModel] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([])
  const [openFormModal, setOpenFormModal] = useState(false);
  const [amenitiesOptions, setAmenitiesOptions] = useState([]);
  const [formType, setFormType] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const [showPropertiesList, setShowPropertiesList] = useState(location.state?.showPropertiesList || {});

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Searches");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    };
    const fetchData = async () => {
      let amenities = await getAmenities();
      setAmenitiesOptions(amenities);
    };
    fetchData();
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
   
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 4,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=name+city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["name", "city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "",
        label: "Property Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Type",
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary" },
          { label: "Flat", value: "Flat", id: "msr", color: "secondary" },
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Bungalow", id: "msr", color: "success" },
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr", color: "primary" },
          { label: "Flat", value: "Flat", id: "msr", color: "secondary" },
          { label: "Duplex", value: "Duplex", id: "msr", color: "warning" },
          { label: "Bungalow", value: "Bungalow", id: "msr", color: "success" },
          { label: "Commercial", value: "Commercial", id: "msr", color: "danger" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "price",
      //   fieldName: "price",
      //   type: "number",
      //   placeholder: "Price",
      //   value: "",
      //   label: "Price",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Price / Rent",
      //   derivedValue: "price=undefined",
      //   capitalizeTableText: false,
      //   sortable: true,
      //   filter: false,
      //   id: "price",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "true",
      //   isFieldRequired: "true",
      //   required: true,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "right",
      //   show: true,
      //   field: "price",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      //   fieldType: 'customType',
      //   customFieldType: 'amount',
      //   filterPlaceholder: `Enter amount(e.g., 100000)`
      // },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: false,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount'
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposit",
        value: "",
        label: "Deposit",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Deposit",
        derivedValue: "deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 1000)`
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "checkbox",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "checkbox",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        multiple: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=plotArea=plotArea=plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Built Up Area",
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Number Of Floors",
        derivedValue: "numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "dropDown",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "floorNumber",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "dropDown",
        filterElement: [
          {
            label: "High",
            value: "High",
            color: "primary",
            id: "hig",
          },
          {
            label: "Medium",
            value: "Medium",
            color: "secondary",
            id: "me",
          },
          {
            label: "Low",
            value: "Low",
            color: "success",
            id: "lo",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'attachField',
        attachField: 'areaUnits',
        filterPlaceholder: `Enter area(e.g., 1000)`
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Availability",
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Furnishing Type",
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        stringType: null,
        header: "Amenities",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "checkbox",
        filterElement: amenitiesOptions,
        multiple: true,
        multipleSelect: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Property Description",
        value: "",
        label: "Property Description",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Description",
        derivedValue: "propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "MonthlyMaintenance",
        value: "",
        label: "MonthlyMaintenance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Monthly Maintenance",
        derivedValue: "monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: true,
        field: "monthlyMaintenanceInIndianFormat",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'amount',
        filterPlaceholder: `Enter amount(e.g., 1000)`
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "checkbox",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "BedRooms",
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "checkbox",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        multiple: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "BathRooms",
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Property Status",
        value: "",
        label: "Property Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "Expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        label0: "New",
        dependentField0: "",
        color0: "primary",
        value0: "New",
        label1: "Sold Out",
        dependentField1: "",
        color1: "secondary",
        value1: "Sold Out",
        label2: "Expired",
        dependentField2: "",
        color2: "success",
        value2: "expired",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "agentSummaryViewCount",
        fieldName: "agentSummaryViewCount",
        type: "text",
        placeholder: "Summary View",
        value: "",
        label: "Views",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Views",
        derivedValue: "agentSummaryViewCount=agentSummaryViewCount=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "agentSummaryViewCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "right",
        show: false,
        field: "agentSummaryViewCount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType: 'customType',
        customFieldType: 'icon',
        iconName: 'eye',
        showDefalutValue: '0'
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "center",
        show: false,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Agent",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "createdByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "updatedByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: '120px',
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        sortable: true,
        controllerId: null,
        fieldType: "Date",
        dateFormat: "DD-MM-YYYY",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: '120px',
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "builderName",
        fieldName: "builderName",
        type: "text",
        placeholder: "Builder Name",
        value: "",
        label: "Builder Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Builder Name",
        derivedValue: "builderName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "builderName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "builderName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availableDate",
        fieldName: "availableDate",
        type: "date",
        placeholder: "Available Date",
        value: "",
        label: "Available Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Available Date",
        derivedValue: "availableDate=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: false,
        id: "availableDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "availableDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "movingDate",
        fieldName: "movingDate",
        type: "date",
        placeholder: "Moving Date",
        value: "",
        label: "Moving Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Moving Date",
        derivedValue: "movingDate=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: false,
        id: "movingDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "movingDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "priceForSqft",
      //   fieldName: "priceForSqft",
      //   type: "number",
      //   placeholder: "Price/Sqft",
      //   value: "",
      //   label: "Price/Sqft",
      //   width: "120px",
      //   addFormOrder: 10,
      //   editFormOrder: 10,
      //   stringType: null,
      //   header: "Price/Sqft",
      //   derivedValue: "priceForSqft=undefined",
      //   capitalizeTableText: false,
      //   sortable: true,
      //   filter: false,
      //   id: "priceForSqft",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinregisterForm: "",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: "",
      //   globalSearchField: "true",
      //   controllerId: null,
      //   isClickable: "",
      //   isAddToIndex: false,
      //   isBulkUploadField: true,
      //   textAlign: "right",
      //   show: true,
      //   field: "priceForSqft",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      //   fieldType: 'customType',
      //   customFieldType: 'amount',
      //   filterPlaceholder: `Enter amount(e.g., 100000)`
      // },
      {
        name: "priceInIndianFormat",
        fieldName: "priceInIndianFormat",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "180px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Price",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "priceInIndianFormat",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "priceInIndianFormat",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minMaxArea",
        fieldName: "minMaxArea",
        type: "number",
        placeholder: "Area",
        value: "",
        label: "Area",
        width: "160px",
        addFormOrder: 12,
        editFormOrder: 13,
        stringType: null,
        header: "Area",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "minMaxArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "minMaxArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectName",
        fieldName: "projectName",
        type: "text",
        placeholder: "Project Name",
        value: "",
        label: "Project Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Project Name",
        derivedValue: "projectName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "projectName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "projectName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        fieldName: "address",
        type: "text",
        placeholder: "Street Address",
        value: "",
        label: "Street Address",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Street Address",
        derivedValue: "streetAddress=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        fieldName: "zipCode",
        type: "text",
        placeholder: "Zip Code",
        value: "",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Zip Code",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "nearbyLandmarks",
        fieldName: "nearbyLandmarks",
        type: "text",
        placeholder: "LandMark",
        value: "",
        label: "LandMark",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "LandMark",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "nearbyLandmarks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "nearbyLandmarks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalTowers",
        fieldName: "totalTowers",
        type: "number",
        placeholder: "Total Towers",
        value: "",
        label: "Total Towers",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Total Towers",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "totalTowers",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalTowers",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalUnits",
        fieldName: "totalUnits",
        type: "number",
        placeholder: "Units",
        value: "",
        label: "Units",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Units",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "totalUnits",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalUnits",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "totalAreaOfProject",
        fieldName: "totalAreaOfProject",
        type: "text",
        placeholder: "Area Of Project",
        value: "",
        label: "Area Of Project",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        stringType: null,
        header: "Area Of Project",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "totalAreaOfProject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "right",
        show: true,
        field: "totalAreaOfProject",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectBrochure",
        fieldName: "projectBrochure",
        type: "fileUpload",
        placeholder: "Project Brochure",
        value: "",
        label: "Project Brochure",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Project Brochure",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectBrochure",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 5,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "projectBrochure",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectVideoLink",
        fieldName: "projectVideoLink",
        type: "text",
        placeholder: "Project Video Link",
        value: "",
        label: "Project Video Link",
        width: "120px",
        addFormOrder: 19,
        editFormOrder: 19,
        stringType: null,
        header: "Project Video Link",
        derivedValue: "projectVideoLink=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectVideoLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "projectVideoLink",
        fieldType: 'WebSite',
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorPlans",
        fieldName: "floorPlans",
        type: "fileUpload",
        placeholder: "Floor Plans",
        value: "",
        label: "Floor Plans",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Floor Plans",
        derivedValue: "floorPlans=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "floorPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "floorPlans",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "paymentPlans",
        fieldName: "paymentPlans",
        type: "fileUpload",
        placeholder: "Payment Plans",
        value: "",
        label: "Payment Plans",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Payment Plans",
        derivedValue: "paymentPlans=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "paymentPlans",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "paymentPlans",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "leagalInformation",
        fieldName: "leagalInformation",
        type: "fileUpload",
        placeholder: "Legal Information/Title Documents",
        value: "",
        label: "Legal Information/Title Documents",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Legal Information/Title Documents",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "leagalInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "other",
        maxFileSize: 6,
        isMultipleRequired: true,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "leagalInformation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "projectFeatures",
        fieldName: "projectFeatures",
        type: "textarea",
        placeholder: "Project Features",
        value: "",
        label: "Project Features",
        width: "120px",
        addFormOrder: 20,
        editFormOrder: 20,
        stringType: null,
        header: "Project Features",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "projectFeatures",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "projectFeatures",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "parkingInformation",
        fieldName: "parkingInformation",
        type: "textarea",
        placeholder: "Parking Information",
        value: "",
        label: "Parking Information",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Parking Information",
        derivedValue: "parkingInformation=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "parkingInformation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "parkingInformation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "constructionPhase",
        fieldName: "constructionPhase",
        type: "textarea",
        placeholder: "Construction Phase",
        value: "",
        label: "Construction Phase",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Construction Phase",
        derivedValue: "constructionPhase=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "constructionPhase",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "constructionPhase",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "approvalAuthority",
        fieldName: "approvalAuthority",
        type: "text",
        placeholder: "Approval Authority / Compliance",
        value: "",
        label: "Approval Authority / Compliance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Approval Authority / Compliance",
        derivedValue: "approvalAuthority=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "approvalAuthority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "approvalAuthority",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
   
    return [
    {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "Sale",
        label: "Property For",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "Flat",
        label: "Property Type",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Bungalow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "checkbox",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Bungalow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "totalPriceMin",
        fieldName: "totalPriceMin",
        type: "dropDown",
        placeholder: "Min",
        value: "",
        label: "Total Price Min",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalPriceMin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: SoldPriceOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "totalPriceMax",
        fieldName: "totalPriceMax",
        type: "dropDown",
        placeholder: "Max",
        value: "",
        label: "Total Price Max",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "totalPriceMax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: SoldPriceOptions,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "checkbox",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        stringType: null,
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            id: "dpc",
          },
        ],
        fieldType: "checkbox",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-4'
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Location",
        value: "Hyderabad",
        label: "Location",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "builtUpAreaMin",
        fieldName: "builtUpAreaMin",
        type: "number",
        placeholder: "Min",
        value: "",
        label: "BuiltUp Area Min",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpAreaMin",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "builtUpAreaMax",
        fieldName: "builtUpAreaMax",
        type: "number",
        placeholder: "Max",
        value: "",
        label: "BuiltUp Area Max",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        stringType: null,
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpAreaMax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "checkbox",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 11,
        editFormOrder: 11,
        stringType: null,
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "checkbox",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-6'
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "checkbox",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        multiple: true,
        multipleSelect: true,
        stringType: null,
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        optionsWidth: 'col-sm-4'
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "checkbox",
        placeholder: "Bed Rooms",
        value: "",
        label: "BedRooms",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        multiple: true,
        multipleSelect: true,     
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        optionsWidth: 'col-sm-4'
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "checkbox",
        placeholder: "Amenties",
        value: "",
        label: "Amenities",
        width: "120px",
        addFormOrder: 12,
        editFormOrder: 12,
        stringType: null,
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        options: amenitiesOptions,
        fieldType: "checkbox",
        multiple: true,
        multipleSelect: true,
        optionsWidth: 'col-sm-6'
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "title",
        fieldName: "title",
        type: "derivedFields",
        placeholder: "Title",
        value: "",
        label: "Title",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: "String",
        header: "Title",
        derivedValue: "title=name+city+locality",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        actions: ["name", "city", "locality"],
        id: "title",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "title",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "validateRequirement",
        fieldName: "validateRequirement",
        type: "textarea",
        placeholder: "ValidateRequirement",
        value: "",
        label: "Validate Requirement",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Validate Requirement",
        derivedValue: "validateRequirement=validateRequirement=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "validateRequirement",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "validateRequirement",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyFor",
        fieldName: "propertyFor",
        type: "radio",
        placeholder: "Property For",
        value: "",
        label: "Property For",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Property For",
        derivedValue: "propertyFor=propertyFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Sale", value: "Sale", id: "msr" },
          { label: "Rent", value: "Rent", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "propertyFor",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyType",
        fieldName: "propertyType",
        type: "radio",
        placeholder: "Property Type",
        value: "",
        label: "Property Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Property Type",
        derivedValue: "propertyType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "propertyType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1RK", value: "1Rk", id: "msr" },
          { label: "Flat", value: "Flat", id: "msr" },
          { label: "Duplex", value: "Duplex", id: "msr" },
          { label: "Bungalow", value: "Banglow", id: "msr" },
          { label: "Commercial", value: "Commercial", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "propertyType",
        showOrHideFields: [],
        label0: "1RK",
        value0: "1Rk",
        label1: "Flat",
        value1: "Flat",
        label2: "Duplex",
        value2: "Duplex",
        label3: "Bungalow",
        value3: "Banglow",
        label4: "Commercial",
        value4: "Commercial",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "price",
        fieldName: "price",
        type: "number",
        placeholder: "Price",
        value: "",
        label: "Price",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Price",
        derivedValue: "price=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "rent",
        fieldName: "rent",
        type: "number",
        placeholder: "Rent",
        value: "",
        label: "Rent",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Rent",
        derivedValue: "rent=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "rent",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "rent",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "deposite",
        fieldName: "deposite",
        type: "number",
        placeholder: "Deposite",
        value: "",
        label: "Deposite",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Deposite",
        derivedValue: "deposite=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "deposite",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "deposite",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facing",
        fieldName: "facing",
        type: "dropDown",
        placeholder: "Facing",
        value: "",
        label: "Facing",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Facing",
        derivedValue: "facing=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "facing",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "North",
            value: "North",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "East",
            value: "East",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "West",
            value: "West",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South",
            value: "South",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North East",
            value: "North East",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "North West",
            value: "North West",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South East",
            value: "South East",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "South West",
            value: "South West",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "facing",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        fieldName: "city",
        type: "text",
        placeholder: "City",
        value: "",
        label: "City",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "City",
        derivedValue: "city=city=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "locality",
        fieldName: "locality",
        type: "text",
        placeholder: "Locality",
        value: "",
        label: "Locality",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Locality",
        derivedValue: "locality=locality=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "locality",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "locality",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "landmark",
        fieldName: "landmark",
        type: "text",
        placeholder: "Landmark",
        value: "",
        label: "Landmark",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Landmark",
        derivedValue: "landmark=landmark=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "landmark",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "landmark",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "plotArea",
        fieldName: "plotArea",
        type: "number",
        placeholder: "Plot Area",
        value: "",
        label: "Plot Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Plot Area",
        derivedValue: "plotArea=plotArea=plotArea=plotArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "plotArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "plotArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "builtUpArea",
        fieldName: "builtUpArea",
        type: "number",
        placeholder: "Built Up Area",
        value: "",
        label: "Built Up Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Built Up Area",
        derivedValue: "builtUpArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "builtUpArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "builtUpArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfFloors",
        fieldName: "numberOfFloors",
        type: "number",
        placeholder: "Number Of Floors",
        value: "",
        label: "Number Of Floors",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Number Of Floors",
        derivedValue: "numberOfFloors=numberOfFloors=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "numberOfFloors",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "numberOfFloors",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floorNumber",
        fieldName: "floorNumber",
        type: "dropDown",
        placeholder: "Floor Number",
        value: "",
        label: "Floor Number",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Floor Number",
        derivedValue: "floorNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "floorNumber",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "dropDown",
        options: [
          {
            label: "High",
            value: "High",
            color: "primary",
            id: "hig",
          },
          {
            label: "Medium",
            value: "Medium",
            color: "secondary",
            id: "me",
          },
          {
            label: "Low",
            value: "Low",
            color: "success",
            id: "lo",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "floorNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "carpetArea",
        fieldName: "carpetArea",
        type: "number",
        placeholder: "Carpet Area",
        value: "",
        label: "Carpet Area",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Carpet Area",
        derivedValue: "carpetArea=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "carpetArea",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "carpetArea",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "availability",
        fieldName: "availability",
        type: "radio",
        placeholder: "Availability",
        value: "",
        label: "Availability",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Availability",
        derivedValue: "availability=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "availability",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          {
            label: "Under Construction",
            value: "Under Construction",
            id: "msr",
          },
          { label: "Ready To Move", value: "Ready To Move", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "availability",
        showOrHideFields: [],
        label0: "Under Construction",
        value0: "Under Construction",
        label1: "Ready To Move",
        value1: "Ready To Move",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "furnishingType",
        fieldName: "furnishingType",
        type: "radio",
        placeholder: "Furnishing Type",
        value: "",
        label: "Furnishing Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Furnishing Type",
        derivedValue: "furnishingType=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "furnishingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Un Furnished", value: "Un Furnished", id: "msr" },
          { label: "Semi Furnished", value: "Semi Furnished", id: "msr" },
          { label: "Furnished", value: "Furnished", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "furnishingType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amenties",
        fieldName: "amenties",
        type: "textarea",
        placeholder: "Amenties",
        value: "",
        label: "Amenties",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Amenties",
        derivedValue: "amenties=amenties=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "amenties",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "amenties",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyDescription",
        fieldName: "propertyDescription",
        type: "textarea",
        placeholder: "Property Description",
        value: "",
        label: "Property Description",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Property Description",
        derivedValue: "propertyDescription=propertyDescription=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "propertyDescription",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "propertyDescription",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "browsePhotos",
        fieldName: "browsePhotos",
        type: "fileUpload",
        placeholder: "Browse Photos",
        value: "",
        label: "Browse Photos",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Browse Photos",
        derivedValue: "browsePhotos=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "browsePhotos",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "My Properties",
        textAlign: "center",
        show: true,
        field: "browsePhotos",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "monthlyMaintenance",
        fieldName: "monthlyMaintenance",
        type: "number",
        placeholder: "MonthlyMaintenance",
        value: "",
        label: "MonthlyMaintenance",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "MonthlyMaintenance",
        derivedValue: "monthlyMaintenance=monthlyMaintenance=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "monthlyMaintenance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "monthlyMaintenance",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bedRooms",
        fieldName: "bedRooms",
        type: "radio",
        placeholder: "Bed Rooms",
        value: "",
        label: "Bed Rooms",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Bed Rooms",
        derivedValue: "bedRooms=bedRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bedRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
          { label: "5", value: "5", id: "msr" },
          { label: "6", value: "6", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bedRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "bathRooms",
        fieldName: "bathRooms",
        type: "radio",
        placeholder: "Bath Rooms",
        value: "",
        label: "Bath Rooms",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Bath Rooms",
        derivedValue: "bathRooms=bathRooms=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "bathRooms",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "1", value: "1", id: "msr" },
          { label: "2", value: "2", id: "msr" },
          { label: "3", value: "3", id: "msr" },
          { label: "4", value: "4", id: "msr" },
        ],
        textAlign: "center",
        show: false,
        field: "bathRooms",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "text",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: false,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "propertyStatus",
        fieldName: "propertyStatus",
        type: "dropDown",
        placeholder: "Property Status",
        value: "",
        label: "Property Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Property Status",
        derivedValue: "propertyStatus=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "propertyStatus",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "New",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Sold Out",
            value: "Sold Out",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Expired",
            value: "expired",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "propertyStatus",
        showOrHideFields: [],
        label0: "New",
        dependentField0: "",
        color0: "primary",
        value0: "New",
        label1: "Sold Out",
        dependentField1: "",
        color1: "secondary",
        value1: "Sold Out",
        label2: "Expired",
        dependentField2: "",
        color2: "success",
        value2: "expired",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "client",
        fieldName: "client",
        type: "relateAutoComplete",
        placeholder: "Client",
        value: "",
        label: "Client",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Client",
        derivedValue: "client=client=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "client",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["firstName"],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "center",
        show: false,
        field: "client",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Clients_firstName",
        type: "text",
        placeholder: "Clients_firstName",
        label: "Clients_firstName",
        header: "Clients firstName",
        width: 110,
        parentField: "client",
        id: "Clients_firstName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "firstName",
        show: true,
        field: "Clients_firstName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.searches}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const getClientSelectModel = (type) => {
    setFormType(type);
    setIsOpenClientSelectModel(true);
  }

  const openClientFormModal = () => {
    setOpenFormModal(true);
  }

  const closeClientFormModal = () => {
    setOpenFormModal(false);
  }

  const resetSearchFilters = () => {
    // Update the location state
    navigate({
      ...location,
      state: {
        ...location.state,
        showPropertiesList: {}
      }
    });
    setShowPropertiesList({});
    localStorage.removeItem('searchFiltersData');
    setTimeout(() => {
      dataTableRef.current?.getDataFromServer();
    }, 200);
  }

  const getFormModal = () => {
    return <FormModal
      openFormModal={openFormModal}
      tableRowData={selectedRowData}
      closeFormModal={closeClientFormModal}
      type={"Client"}
      formType={'add'}
      formFields={() => getClientFormFields()}
      filterCriteria={{}}
      getDataFromServer={dataTableRef.current?.getDataFromServer}
      editRequired={true}
      apiUrl={apiCalls.clients}
      routeTo={'searches'}
      displayViewOfForm={'modal'}
      selectedId={selectedRowData._id}
      isSearch={true}
    />
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      { openFormModal && getFormModal()}
      {amenitiesOptions?.length > 0 &&
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={false}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={false}
          sample={false}
          printRequried={false}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.searches}
          globalSearch={"Search"}
          displayName="Searches"
          type="Searches"
          routeTo={apiCalls.searches}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="left"
          apiResponseKey={apiCalls.searches?.toLowerCase()}
          apiUrl={apiCalls.searches}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          defaultCriteria={[]}
          filterClearRequired={false}
          getClientSelectModel={getClientSelectModel}
          resetSearchFilters={resetSearchFilters}
          globalShareRequired={true}
          locationState={showPropertiesList}
          customType={"myproperties"}
          hideActions={true}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {isOpenClientSelectModel ? (
        <ClientSelectModal
          isOpenClientSelectModel={isOpenClientSelectModel}
          setIsOpenClientSelectModel={setIsOpenClientSelectModel}
          openClientFormModal={openClientFormModal}
          properties={dataTableRef.current?.properties}
          saveSearchFilterObject={dataTableRef.current?.saveSearchFilterObject}
          locationState={showPropertiesList}
          formType={formType}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="searches"
          apiUrl={apiCalls.searches}
        />
      ) : null}
    </span>
  );
};

export default Searches;
