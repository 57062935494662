import moment from "moment";
import config from "./config";

export const PropertyAeraOptions  = [
    {
      label: "Sq.Ft.",
      value: "sqft",
      color: "success",
    },
    {
      label: "Sq.Yards",
      value: "sqyards",
      color: "danger",
    },
    {
      label: "Sq.M.",
      value: "sqm",
      color: "warning",
    }, 
    {
        label: "Aankadam",
        value: "aankadam",
        color: "secondary",
    },
    {
        label: "Guntha",
        value: "guntha",
        color: "success",
    },
    {
        label: "Cents",
        value: "cents",
        color: "danger",
    },
    {
        label: "Acres",
        value: "acres",
        color: "info",
    },
    {
        label: "Hectares",
        value: "hectares",
        color: "dark",
    },
    {
      label: "Grounds",
      value: "grounds",
      color: "info",
    },
    {
        label: "Kanal",
        value: "kanal",
        color: "success",
    },
    {
      label: "Rood",
      value: "rood",
      color: "primary",
    },
    {
      label: "Chataks",
      value: "chataks",
      color: "dark",
    },
    {
      label: "Perch",
      value: "perch",
      color: "light",
    },
    {
      label: "Ares",
      value: "ares",
      color: "danger",
    },
    {
      label: "Biswa",
      value: "biswa",
      color: "warning",
    },
    {
      label: "Bigha",
      value: "bigha",
      color: "secondary",
    },
    {
      label: "Kottah",
      value: "kottah",
      color: "primary",
    },
    {
      label: "Marla",
      value: "marla",
      color: "light",
    },
  ];
  
export const updateCkEditorValues = (values) => {
  values = values?.replace(/-/g, '');
  const tempElement = document.createElement('div');
  tempElement.innerHTML = values;
  return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
}

export const dateAndTimeFormat = (timestamp, tableItem) => {
  if (!timestamp || isNaN(new Date(timestamp).getTime())) return null;

  const formatTime = (hour, minute) => {
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert to 12-hour format
    return `${hour.toString().padStart(2, '0')}:${minute} ${period}`;
  };

  let formattedDate, formattedTime;

  if (typeof timestamp === 'string') {
    const [datePart, timePart] = timestamp.split('T');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');
    formattedDate = `${day}-${month}-${year}`;
    formattedTime = formatTime(parseInt(hour, 10), minute);
  } else {
    const localDate = moment.utc(timestamp).local();
    formattedDate = localDate.format('DD-MM-YYYY');
    formattedTime = formatTime(localDate.hours(), localDate.minutes().toString().padStart(2, '0'));
  }

  return (
    <span style={tableItem?.style} title={`${formattedDate} ${formattedTime}`}>
      {tableItem?.fieldUse === "dateAndTime" ? `${formattedDate} ${formattedTime}` : formattedTime}
    </span>
  );
};

